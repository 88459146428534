<template>
  <v-card>
    <v-card-title>Materias - {{ info["Usuario"] }}</v-card-title>
    <v-divider></v-divider>
    <v-card-text style="height: 500px">
      <v-container>
        <v-container>
          <v-row v-for="(option, o) in dataCursos" v-bind:key="o">
            <v-col class="paddingCol" cols="12" md="12">
              <h4>{{ option.curso }}</h4>
            </v-col>
            <v-col class="paddingCol" cols="12" md="4">
              <v-select
                label="Estado"
                :items="['DPS', 'LAZARO', 'OK']"
                v-model="option.estado"
                @change="estadoByM(option.curso, option.estado)"
              ></v-select>
            </v-col>
            <v-col class="paddingCol" cols="12" md="6">
              <v-text-field
                v-model="option.obs"
                :append-icon="option.obs != null ? 'mdi-check' : ''"
                @click:append="saveObs(option.curso, option.obs)"
                label="Notas por materia"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="accent" text @click="$emit('verMaterias', false)"
        >Cerrar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import firebase from "firebase";
import { host } from "../Tools/variables";
import axios from "axios";
export default {
  name: "VentasVerMaterias",
  props: ["dataCursos", "info", "infoCli"],

  methods: {
    estadoByM(sigla, alerta) {
      var div = sigla.split("-");
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "agregar");
          fd.append("titulo", "Alerta");
          fd.append("materia", div[0]);
          fd.append("periodo", div[1]);
          fd.append("tipoAlerta", "Alerta");
          fd.append("comentarios", alerta);
          fd.append("opcionesTipo", "");
          fd.append("estudianteId", this.infoCli.estudiante);
          axios.post(host + "/alertas", fd).then((result) => {
            let data = result.data.text;
            this.mt = false;
            if (data != "OK") {
              //     this.$alert('Estado de usuario guardado satisfactoriamente')
              // }else{
              this.$alert(
                "Error al guardar el ajuste de estado de usuario, intentelo nuevamente"
              );
            } else {
              this.$alert("Estado de la materia " + sigla + " Actualizado");
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },
    saveObs(sigla, obs) {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("materiaPeriodo", sigla);
          fd.append("observacion", obs);
          fd.append("estudianteId", this.infoCli.estudiante);
          axios.post(host + "/editaObservacionMateria", fd).then((result) => {
            let data = result.data.text;
            if (data != "OK") {
              //     this.$alert('Estado de usuario guardado satisfactoriamente')
              // }else{
              this.$alert(
                "Error al guardar el ajuste de estado de usuario, intentelo nuevamente"
              );
            } else {
              this.$alert("Observación de la materia " + sigla + " Agregada");
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.paddingCol {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
